import {v1 as uuid} from 'uuid';
import {get, defer} from 'lodash';
import * as Sentry from '@sentry/browser';
import {toast} from '../../utils/toast';

import Api from '../../services/api';
import store from '../../utils/store/configure';
import history from '../../helpers/router/history';
import {unprocessableEntityStatusCode} from '../../constants/responseCodes';
import {subscribeToEvents, unsubscribeToEvents} from '../../services/websocket';
import {hasTokensStored, isUrlWhitelisted, parseFirstError} from '../../helpers/auth';
import {fetchUserProfile, setAfterLogin, toggleAppBooting} from '../settings';
import {
  CONFIRMATION, LOGIN, FACEBOOK_CALLBACK, GOOGLE_CALLBACK,
  ADDITIONAL_INFO,
  DASHBOARD,
  SOCIAL_LOGIN_COMPLETE
} from '../../constants/routes';
import {clearLocalStorage} from '../../helpers/storage';
import {getParneroParner} from '../../helpers/cookies/partnero';

export const login = ({username, password}) => async (dispatch) => {
  try {
    const {data} = await Api.auth.login({username, password});

    localStorage.setItem('token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    dispatch(setAfterLogin(true));
    dispatch(initializeApp(true));
  } catch (e) {
    const {data} = e?.response || {};

    toast.error(!data?.error ? 'Couldnt reach server' : 'User credentials are invalid');

    return data;
  }
};

export const signup = ({
  fullName,
  businessName,
  type,
  agencyType,
  agencyName,
  industry,
  findUs,
  email,
  password,
  invitationToken,
}) => async () => {
  try {
    const {data} = await Api.auth.signup({
      full_name: fullName,
      company_name: type === 'agency' ? agencyName : businessName,
      type,
      agency_type: agencyType,
      industry,
      find_us: findUs,
      email,
      password,
      token: invitationToken,
      affiliate_id: getParneroParner()
    });

    localStorage.setItem('token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);

    history.push('/auth/verify_email');
  } catch (e) {
    if (e.response) {
      const {data} = e.response;

      const errors = get(data, 'errors', {general: 'Couldn\'t create account'});

      const errorsList = Object.values(errors);

      for (let i = 0; i < errorsList.length; i++) {
        toast.error(errorsList[i]);
      }

      return data;
    }

    if (e.request) {
      console.error('No response received:', e.request);
    } else {
      console.error('Unexpected error:', e.message);
    }
  }
  try {
    // Check if the `po` function exists before calling it
    if (typeof po === 'function') {
      const user = {
        key: email, // Replace with dynamic value if available
        name: `${fullName}`,
        email
      };

      // eslint-disable-next-line no-undef
      po('customers', 'signup', {data: user});
    }
  } catch (error) {
    console.error('Error in Partnero:', error);
  }
};

export const signupUpdate = ({
  businessName,
  type,
  agencyType,
  agencyName,
  industry,
  findUs,
  sessionId,
}) => async (dispatch) => {
  try {
    const response = await Api.auth.signupUpdate({

      company_name: type === 'agency' ? agencyName : businessName,
      type,
      agency_type: agencyType,
      industry,
      find_us: findUs,
      session_id: sessionId
    });

    if (response.status === 200 && !sessionId) {
      await dispatch(fetchUserProfile());
      toast.success('Account updated successfully');
      history.push(DASHBOARD);
    } else if (response.status === 200 && sessionId) {
      toast.success('Account verified');
      history.push(SOCIAL_LOGIN_COMPLETE);
    } else {
      Sentry.captureException(response);
      toast.error('Couldn\'t update account, please check information');
    }
  } catch (e) {
    Sentry.captureException(e);

    if (e.response) {
      const {data} = e.response;

      const errors = get(data, 'errors', {general: 'Couldn\'t update account'});

      const errorsList = Object.values(errors);

      for (let i = 0; i < errorsList.length; i++) {
        toast.error(errorsList[i]);
      }

      return data;
    }

    if (e.request) {
      console.error('No response received:', e.request);
    } else {
      console.error('Unexpected error:', e.message);
    }
  }
};

export const appsumoSignup = ({
  firstName,
  lastName,
  companyName,
  email,
  password,
  code
}) => async (dispatch) => {
  try {
    const {data} = await Api.auth.appsumoSignup({
      first_name: firstName,
      last_name: lastName,
      email,
      company_name: companyName,
      password,
      password_confirmation: password,
      code
    });

    localStorage.setItem('token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);

    await dispatch(initializeApp(true));
  } catch (e) {
    const {data} = e.response;

    const errors = get(data, 'errors', {general: 'Couldn\'t create account'});

    const errorsList = Object.values(errors);

    for (let i = 0; i < errorsList.length; i++) {
      for (let j = 0; j < errorsList[i].length; j++) {
        toast.error(errorsList[i][j]);
      }
    }

    throw new Error();
  }
};

export const fbLogin = async (props) => {
  if (!props || !props.accessToken) {
    return;
  }

  const {accessToken} = props;

  try {
    const result = await Api.auth.login({
      access_token: accessToken,
      provider: 'facebook',
      grant_type: 'social',
      client_param: 'web',
      affiliate_id: getParneroParner()
    });

    const {access_token, refresh_token} = result.data;

    localStorage.setItem('token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
  } catch (e) {
    toast.error(
      'Failed to authorized with Facebook account! Email is required for authorization. Please try again or contact us support@attentioninsight.com'
    );
  }
  store.dispatch(setAfterLogin(true));
  await store.dispatch(initializeApp(true));
};

export const gLogin = async (props) => {
  if (!props || !props.access_token) {
    return;
  }
  const accessToken = props.access_token;

  try {
    const result = await Api.auth.login({
      access_token: accessToken,
      provider: 'google',
      grant_type: 'social',
      client_param: 'web',
      affiliate_id: getParneroParner()
    });

    // print error in case of failure
    const {access_token, refresh_token} = result.data;

    localStorage.setItem('token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
  } catch (e) {
    Sentry.captureException(e);
    toast.error('Failed to authorized with Google account! Please try again later or use email.');
  }
  await store.dispatch(setAfterLogin(true));
  await store.dispatch(initializeApp(true));
};

export const verifyEmail = ({token, email}) => async (dispatch) => {
  try {
    await Api.auth.verifyEmail({token, email});
    toast.success('Email verified!');
  } catch {
    toast.error('Verification token expired');
  } finally {
    await dispatch(setAfterLogin(true));
    await dispatch(initializeApp(true));
  }
};

export const forgotPassword = (email) => async () => {
  try {
    await Api.auth.forgotPassword({email});
    toast.success('We emailed you the password reset link!');
    history.push('/auth/signin');
  } catch (err) {
    const error = parseFirstError(err, 'That email is not known to our system');

    toast.error(error);
  }
};

export const resetPassword = ({email, token, password}) => async () => {
  try {
    await Api.auth.resetPassword({email, token, password});
    toast.success('You\'r password has been changed!');
    history.push('/auth/signin');
  } catch (err) {
    const error = parseFirstError(err, 'Link expired or not valid');

    toast.error(error);

    return error;
  }
};

export const resendEmail = () => async () => {
  try {
    const {data} = await Api.auth.resendEmail();

    toast.success(`An email has been sent to ${data.email}`);
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t resend email.');

    toast.error(error);
  }
};

export const logout = (isAuthenticated) => async (dispatch) => {
  try {
    defer(() => dispatch({type: 'AUTH/LOGOUT'}));

    // If unsubscribeToEvents is async:
    unsubscribeToEvents();

    if (isAuthenticated) await Api.auth.logout();
  } catch (error) {
    console.error('Error during logout:', error?.message || error);
    // Handle the error as necessary
  } finally {
    dispatch(toggleAppBooting(false));
    clearLocalStorage();
    history.replace('/auth/signin');
  }
};

export const initializeApp = (redirectAfter) => async (dispatch) => {
  const path = window.location.pathname;

  if (!hasTokensStored()) {
    dispatch(toggleAppBooting(false));

    // For only a few urls, we dont wanna redirect if the user doesnt have a token.
    if (isUrlWhitelisted() || path.includes('/auth/')) {
      return;
    }

    return history.push('/auth/signin');
  }

  // Allow users to break app booting if they hit logout directly
  if (
    path === '/auth/logout' ||
    path.startsWith('/auth/reset/') ||
    path === '/auth/demo' ||
    path.startsWith(FACEBOOK_CALLBACK) ||
    path.startsWith(GOOGLE_CALLBACK)
  ) {
    dispatch(toggleAppBooting(false));

    return;
  }

  // We use this correlation ID in order to only download files on the device
  // that got requested from.
  if (!localStorage.getItem('correlationId')) {
    localStorage.setItem('correlationId', uuid());
  }

  try {
    // Tell the app that we're in booting mode so we can show a loader..
    await dispatch(toggleAppBooting(true));

    if (!path.includes('/auth/verify_email')) {
      // Fetch user profile
      const user = await dispatch(fetchUserProfile());

      // Subscribe user to websocket events.
      subscribeToEvents(user, store);
    }

    // Redirect to main dashboard
    if (redirectAfter) {
      history.push('/');
    }
  } catch (err) {
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === unprocessableEntityStatusCode
    ) {
      if (err.response.data && err.response.data.errors && err.response.data.errors.request_info) {
        history.push(ADDITIONAL_INFO);
      } else {
        history.push(CONFIRMATION);
      }
    } else {
      history.push(LOGIN);
    }
  } finally {
    await dispatch(toggleAppBooting(false));
  }
};
