/* eslint-disable no-param-reassign */
import {isEmpty, pick, get} from 'lodash';
import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import {toast} from '../../utils/toast';
import Api from '../../services/api';
import history from '../../helpers/router/history';

import {getUrlWithToken, parseFirstError, getCorrelationId} from '../../helpers/auth';
import {getSelectedCompany} from '../settings';
import {updateStudyShareStatus as updateStudyShareStatusInsideProject} from '../projects';
import {subscribeOnce} from '../../services/websocket';
import {CUSTOM_EVENTS, triggerCustomEvent} from '../../helpers/customEvents';
import {FEATURES_FLAGS} from '../../constants/features';

export const setList = createAction('STUDIES/SET_LIST');
export const setVideoList = createAction('STUDIES/SET_VIDEO_LIST');
export const setFullList = createAction('STUDIES/SET_FULL_LIST');
export const setFullListForComparison = createAction('STUDIES/SET_FULL_LIST_FOR_COMPARISON');
export const setListFilters = createAction('STUDIES/SET_LIST_FILTERS');
export const setVideoListFilters = createAction('STUDIES/SET_VIDEO_LIST_FILTERS');
export const setFullListFilters = createAction('STUDIES/SET__FULL_LIST_FILTERS');
export const setDetails = createAction('STUDIES/SET_DETAILS');
export const clearDetails = createAction('STUDIES/CLEAR_DETAILS');
export const deleteStudy = createAction('STUDIES/DELETE_STUDY');
export const setProgress = createAction('STUDIES/SET_PROGRESS');
export const setLoadingError = createAction('STUDIES/SET_ERROR');
export const setCreation = createAction('STUDIES/SET_CREATION');
export const storeAoi = createAction('STUDIES/STORE_AOI');
export const updateAoi = createAction('STUDIES/UPDATE_AOI');
export const clearAutoAoisFor = createAction('STUDIES/CLEAR_AOIS_FOR');
export const clearAoisFor = createAction('STUDIES/CLEAR_AUTO_AOIS_FOR');
export const deleteAoi = createAction('STUDIES/DELETE_AOI');
export const clearCreation = createAction('STUDIES/CLEAR_CREATION');
export const toggleFetching = createAction('STUDIES/TOGGLE_FETCHING');
export const updateStudyState = createAction('STUDIES/UPDATE_STUDY');
export const updateResource = createAction('STUDIES/UPDATE_RESOURCE');
export const updateStudyShareStatus = createAction('STUDIES/UPDATE_STUDY_SHARE_STATUS');
export const updateStudyStatus = createAction('STUDIES/UPDATE_STUDY_STATUS');
export const addPreviewFile = createAction('STUDIES/ADD_PREVIEW_FILE');
export const removePreviewFile = createAction('STUDIES/REMOVE_PREVIEW_FILE');
export const addScrapedUrl = createAction('STUDIES/ADD_SCRAPED_URL');
export const markAllAsPending = createAction('STUDIES/MARK_ALL_AS_PENDING');
export const showGenerativeRecommendation = createAction('STUDIES/OPEN_GENERATIVE_MODAL');
export const showGenerativeAoiRecommendation = createAction('STUDIES/OPEN_GENERATIVE_MODAL_AOI');
export const addGenerativeRecommendation = createAction('STUDIES/ADD_GENERATIVE_RECOMMENDATION');
export const cancelRecommendationRequest = createAction('STUDIES/CANCEL_RECOMMENDATION_REQUEST');
export const addAoiGenerativeRecommendation = createAction(
  'STUDIES/ADD_AOI_GENERATIVE_RECOMMENDATION'
);
export const resetAoiGenerativeRecommendation = createAction(
  'STUDIES/RESET_AOI_GENERATIVE_RECOMMENDATION'
);
export const setViewport = createAction('STUDIES/SET_VIEWPORT_VALUES');
export const resetViewport = createAction('STUDIES/RESET_VIEWPORT_VALUES');
export const showAoisLayer = createAction('STUDIES/SHOW_AOIS_LAYER');
export const showViewportLayer = createAction('STUDIES/SHOW_VIEWPORT_LAYER');
export const markAoiAsOpen = createAction('STUDIES/MARK_AOI_AS_OPEN');
export const markAllAoisAsOpen = createAction('STUDIES/MARK_ALL_AOIS_AS_OPEN');
export const markAllAoisAsClosed = createAction('STUDIES/MARK_ALL_AOIS_AS_CLOSED');

export const setAnalysisMap = createAction('STUDIES/SET_ANALYSIS_MAP');

const MAX_PROGRESS = 100;

const HTTP_CODE_NOT_PROCESSED = 422;

export const initialState = {
  list: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'date:desc',
      items_per_page: 26
    },
    didSearch: false,
    isLoading: false
  },
  showGenerativeRecommendation: null,
  showGenerativeAoiRecommendation: null,
  cancelRecommendationRequest: null,
  videoList: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'date:desc',
      items_per_page: 26
    },
    didSearch: false,
    isLoading: false
  },
  fullList: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'date:desc',
      items_per_page: 99
    },
    didSearch: false,
    isLoading: false
  },
  fullListForComparison: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'date:desc',
      items_per_page: 99999
    },
    didSearch: false,
    isLoading: false
  },
  creation: {
    studyId: '',
    data: {},
    isLoading: false,
    isScraping: false,
    isAdding: false,
    isRemoving: false,
    progress: 0,
    loadingError: false
  },
  details: {
    data: [{annotations: [], generative: []}],
    meta: {},
    didSearch: false,
    isLoading: false,
    isSubmitting: false
  },
  viewport: {
    selectionTop: 0,
    selectionHeight: 0
  },
  map: FEATURES_FLAGS.HEATMAP,
  layers: {
    aois: true,
    viewport: true
  }
};

export default createReducer(initialState, {
  [cancelRecommendationRequest]: (state, action) => {
    state.cancelRecommendationRequest = action.payload;
  },
  [setAnalysisMap]: (state, action) => {
    state.map = action.payload;
  },
  [showGenerativeRecommendation]: (state, action) => {
    state.showGenerativeRecommendation = action.payload;
  },
  [showGenerativeAoiRecommendation]: (state, action) => {
    state.showGenerativeAoiRecommendation = action.payload;
  },
  [addGenerativeRecommendation]: (state, action) => {
    if (action.payload?.data) {
      // Check if details.data[0] exists to safely add to its generative array
      if (state.details.data[0]) {
        return {
          ...state,
          details: {
            ...state.details,
            data: [
              {
                ...state.details.data[0], // Copy all items before the one we're updating
                generative: [...state.details.data[0].generative, action.payload.data]
              }
            ]
          }
        };
      }
    }
  },
  [addAoiGenerativeRecommendation]: (state, action) => {
    if (action.payload?.data) {
      // Check if details.data[0] exists to safely add to its generative array
      if (state.details?.data?.[0]) {
        const updatedData = state.details.data[0].annotations.map((annotation) => {
          if (annotation.data.id === action.payload.data.aoiId) {
            const newAnnotation = {
              ...annotation,
              data: {
                ...annotation.data,
                generative: action.payload.data
              }
            };

            return newAnnotation;
          }

          return annotation;
        });

        // Return the updated state
        return {
          ...state,
          details: {
            ...state.details,
            data: [
              {
                ...state.details.data[0],
                annotations: updatedData
              }
            ]
          }
        };
      }
    }

    return state;
  },
  [resetAoiGenerativeRecommendation]: (state, action) => {
    if (action.payload) {
      // Check if details.data[0] exists to safely add to its generative array
      if (state.details?.data?.[0]) {
        const updatedData = state.details.data[0].annotations.map((annotation) => {
          if (annotation.data.id === action.payload?.aoiId) {
            const newAnnotation = {
              ...annotation,
              data: {
                ...annotation.data,
                generative: null
              }
            };

            return newAnnotation;
          }

          return annotation;
        });

        // Return the updated state
        return {
          ...state,
          details: {
            ...state.details,
            data: [
              {
                ...state.details.data[0],
                annotations: updatedData
              }
            ]
          }
        };
      }
    }

    return state;
  },
  [setList]: (state, action) => {
    state.list.data = action.payload.data;
    state.list.meta = action.payload.meta;
    state.list.isLoading = false;
    state.list.didSearch = true;
  },
  [setVideoList]: (state, action) => {
    state.videoList.data = action.payload.data;
    state.videoList.meta = action.payload.meta;
    state.videoList.isLoading = false;
    state.videoList.didSearch = true;
  },
  [setListFilters]: (state, action) => {
    state.list.filters = action.payload.filters;
  },
  [setVideoListFilters]: (state, action) => {
    state.videoList.filters = action.payload.filters;
  },
  [setFullList]: (state, action) => {
    state.fullList.data = action.payload.data;
    state.fullList.meta = action.payload.meta;
    state.fullList.isLoading = false;
    state.fullList.didSearch = true;
  },
  [setFullListForComparison]: (state, action) => {
    state.fullListForComparison.data = action.payload.data;
    state.fullListForComparison.meta = action.payload.meta;
    state.fullListForComparison.isLoading = false;
    state.fullListForComparison.didSearch = true;
  },
  [setFullListFilters]: (state, action) => {
    state.fullList.filters = action.payload.filters;
  },
  [setDetails]: (state, action) => {
    state.details.data = action.payload.data;
    state.details.meta = action.payload.meta;
    state.details.isLoading = false;
    state.details.didSearch = true;

    if (typeof state.details.meta.study.benchmark.benchmark !== 'undefined') {
      state.details.meta.study.benchmark.benchmark = JSON.parse(
        state.details.meta.study.benchmark.benchmark
      );
    }
  },
  [updateStudyState]: (state, action) => {
    if (state.details.meta.study) {
      if (!isEmpty(state.details.meta)) {
        state.details.meta.study.name = action.payload.name;
      } else {
        state.creation.data.study.name = action.payload.name;
      }
    }
  },
  [clearDetails]: (state) => {
    state.details = initialState.details;
  },
  [setProgress]: (state, action) => {
    state.creation.progress = action.payload;
  },
  [setLoadingError]: (state, action) => {
    state.creation.loadingError = action.payload;
  },
  [deleteStudy]: (state, action) => {
    state.list.data = state.list.data.filter((e) => e.id !== action.payload);
  },
  [setCreation]: (state, action) => {
    state.creation.isLoading = false;
    state.creation = {...state.creation, ...action.payload};
  },
  [clearAoisFor]: (state, action) => {
    state.details.data = state.details.data.map((s) => {
      if (s.resourceId === action.payload) {
        return {...s, annotations: []};
      }

      return s;
    });
  },
  [clearAutoAoisFor]: (state, action) => {
    state.creation.data.resources.data = state.creation.data.resources.data.map((res) => {
      if (res.resourceId === action.payload) {
        const annotations = res.annotations.filter((annotation) => {
          const sourceId = 2;

          // TODO hardcoded automatic source id
          return parseInt(annotation.data.source) !== sourceId;
        });

        return {...res, annotations};
      }
      const {annotations} = res;

      return {...res, annotations};
    });
  },
  [deleteAoi]: (state, action) => {
    if (typeof state.creation.data.resources !== 'undefined') {
      state.creation.data.resources.data = state.creation.data.resources.data.map((res) => {
        const annotations = res.annotations.filter((annotation) => {
          return annotation.data.id !== action.payload;
        });

        return {...res, annotations};
      });
    } else if (typeof state.details.data !== 'undefined') {
      state.details.data = state.details.data.map((res) => {
        const annotations = res.annotations.filter((annotation) => {
          return annotation.data.id !== action.payload;
        });

        return {...res, annotations};
      });
    }
  },
  [storeAoi]: (state, action) => {
    state.details.data = state.details.data.map((r) => {
      if (r.resourceId === action.payload.resourceId) {
        return {
          ...r,
          annotations: [
            pick(action.payload, ['data', 'geometry', 'recommendations', 'benchmark']),
            ...r.annotations
          ]
        };
      }

      return r;
    });
  },
  [updateAoi]: (state, action) => {
    const {
      id, text, recommendations, benchmark
    } = action.payload;

    state.details.data.forEach((item) => {
      const existingAnnotation = item.annotations.find((annotation) => annotation.data.id === id);

      if (existingAnnotation) {
        Object.assign(existingAnnotation.data, pick({
          text, recommendations, benchmark
        }, ['text', 'recommendations', 'benchmark']));
      }
    });
  },
  [markAoiAsOpen]: (state, action) => {
    state.details.data.forEach((item) => {
      if (item.resourceId === action.payload.resourceId) {
        item.annotations.forEach((annotation) => {
          if (annotation.data.id === action.payload.aoiId) {
            annotation.isOpen = true; // Mark isOpen true if aoiId matches
          } else {
            annotation.isOpen = false; // Close other open AOIs in Benchmarks
          }
        });
      }
    });
  },
  [markAllAoisAsOpen]: (state) => {
    state.details.data = state.details.data.map((r) => ({
      ...r,
      annotations: r.annotations.map((a) => ({
        ...a,
        isOpen: true
      }))
    }));
  },
  [markAllAoisAsClosed]: (state) => {
    state.details.data = state.details.data.map((r) => ({
      ...r,
      annotations: r.annotations.map((a) => ({
        ...a,
        isOpen: false
      }))
    }));
  },
  [clearCreation]: (state) => {
    state.creation = initialState.creation;
  },
  [toggleFetching]: (state, action) => {
    if (action.payload.value) {
      state[action.payload.key][action.payload.value] = action.payload.toggle;

      return;
    }

    state[action.payload.key].isLoading = action.payload.toggle;
  },
  [updateResource]: (state, action) => {
    state.details.data = state.details.data.reduce((list, current) => {
      let buffer;

      if (current.resourceId === action.payload.id) {
        buffer = action.payload.data;
      } else {
        buffer = [current];
      }

      return [...list, ...buffer];
    }, []);
  },
  [updateStudyShareStatus]: (state, action) => {
    state.list.data = state.list.data.map((study) => {
      if (study.id === action.payload.studyId) {
        return {...study, isShared: action.payload.isShared};
      }

      return study;
    });

    if (state.details.meta.study && state.details.meta.study.id === action.payload.studyId) {
      state.details.meta.study.share = action.payload.isShared;
    }
  },
  [updateStudyStatus]: (state, action) => {
    if (!isEmpty(state.details.meta)) {
      state.details.meta.study.statusKey = 'finished';
    }
    state.list.data = state.list.data.map((x) => {
      if (x.id === action.payload.studyId) {
        return {...x, statusKey: 'finished'};
      }

      return x;
    });
  },
  [addPreviewFile]: (state, action) => {
    // Depending in which flow we are in (creation or study images editing) we have
    // to load the preview in different places of the store.
    if (!action.payload.isEditMode) {
      state.details.data.push(action.payload);
    } else if (!isEmpty(state?.creation?.data)) {
      state.creation.data.resources.data.push(action.payload);
    }
  },
  [removePreviewFile]: (state, action) => {
    // Depending in which flow we are in (creation or study images editing) we have
    // to remove the preview in different places of the store.
    if (!action.payload.isEditMode) {
      state.details.data = state.details.data.filter((x) => x.resourceId !== action.payload.id);
    } else {
      state.creation.data.resources.data = state.creation.data.resources.data.filter(
        (x) => x.resourceId !== action.payload.id
      );
    }
  },
  [addScrapedUrl]: (state, action) => {
    // Reset is scraping state
    state.creation.isScraping = false;

    if (action.payload.isEditMode) {
      if (state.creation?.data?.resources?.data) {
        state.creation.data.resources.data = [
          ...state.creation.data.resources.data,
          ...action.payload.resource
        ];
      } else {
        Sentry.captureException('Error adding scraped url to study, creation data is missing', action.payload);
      }
    } else if (state.details?.data) {
      state.details.data = [...state.details.data, ...action.payload.resource];
    } else {
      Sentry.captureException('Error adding scraped url to study', action.payload);
    }
  },
  [markAllAsPending]: (state) => {
    state.details.data = state.details.data.map((x) => {
      if (['draft', 'scrapped'].includes(x.statusKey)) {
        return {...x, statusKey: 'pending'};
      }

      return x;
    });

    state.details.meta.study.statusKey = 'pending';
  },
  [setViewport]: (state, action) => {
    state.viewport.selectionTop = action.payload.selectionTop;
    state.viewport.selectionHeight = action.payload.selectionHeight;
  },
  [resetViewport]: (state) => {
    state.viewport.selectionTop = 0;
  },
  [showAoisLayer]: (state, action) => {
    state.layers.aois = action.payload;
  },
  [showViewportLayer]: (state, action) => {
    state.layers.viewport = action.payload;
  }
});

/* ACTION CREATORS */
export const getAll = ({filters}) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    // Desctruct companyId
    const {id: companyId} = company;

    /* Set loading state */
    dispatch(toggleFetching({key: 'list', toggle: true}));

    /* Fetch studies list */
    const {data} = await Api.studies.getList({companyId, filters});

    /* Store studies list */
    dispatch(setList(data));
    dispatch(setListFilters({filters}));
  } catch (err) {
    if (err) {
      toast.error('Couldn\'t fetch your studies');
    }
  } finally {
    dispatch(toggleFetching({key: 'list', toggle: false}));
  }
};

export const getAllVideos = ({filters}) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    // Desctruct companyId
    const {id: companyId} = company;

    /* Set loading state */
    dispatch(toggleFetching({key: 'list', toggle: true}));

    /* Fetch studies list */
    const {data} = await Api.studies.getVideoList({companyId, filters});

    /* Store studies list */
    dispatch(setVideoList(data));
    dispatch(setVideoListFilters({filters}));
  } catch (err) {
    if (err) {
      console.error(err);
      toast.error('Couldn\'t fetch your studies');
    }
  } finally {
    dispatch(toggleFetching({key: 'list', toggle: false}));
  }
};

export const getFullList = (type) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    // Desctruct companyId
    const {id: companyId} = company;

    /* Set loading state */
    dispatch(toggleFetching({key: 'fullList', toggle: true}));

    if (type === 'video') {
      /* Fetch studies list */
      const {data} = await Api.studies.getVideoList({
        companyId,
        filters: initialState.fullList.filters
      });

      /* Store studies list */
      dispatch(setFullList(data));
    } else {
      /* Fetch studies list */
      const {data} = await Api.studies.getList({
        companyId,
        filters: initialState.fullList.filters
      });

      /* Store studies list */
      dispatch(setFullList(data));
    }
  } catch (err) {
    if (err) {
      toast.error('Couldn\'t fetch your studies');
    }
  } finally {
    dispatch(toggleFetching({key: 'fullList', toggle: false}));
  }
};

export const getFullListForComparison = () => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    // Desctruct companyId
    const {id: companyId} = company;

    /* Set loading state */
    dispatch(toggleFetching({key: 'fullListForComparison', toggle: true}));

    const paramsToPass = {
      ...initialState.fullList.filters,
      type: 'no-thumbnail'
    };

    /* Fetch studies list */
    const {data} = await Api.studies.getList({
      companyId,
      filters: paramsToPass
    });

    /* Store studies list */
    dispatch(setFullListForComparison(data));
  } catch (err) {
    if (err) {
      toast.error('Couldn\'t fetch your studies');
    }
  } finally {
    dispatch(toggleFetching({key: 'fullListForComparison', toggle: false}));
  }
};

export const getById = (studyId, isAuthenticated) => async (dispatch) => {
  try {
    /* Set loading state */
    dispatch(toggleFetching({key: 'details', toggle: true}));

    /* Fetch study by id */
    const {data} = isAuthenticated
      ? await Api.studies.getById(studyId)
      : await Api.studies.getSharedById(studyId);

    /* Store study Analysis */
    dispatch(setDetails(data));

    return data.data;
  } catch (err) {
    if (err) {
      const error = parseFirstError(err, `Couldn't fetch study #${studyId}.`);

      toast.error(error);

      if (!isAuthenticated) {
        history.push('/auth/signin');
      }
    }
  } finally {
    dispatch(toggleFetching({key: 'details', toggle: false}));
  }
};

export const getVideoById = (studyId, isAuthenticated) => async (dispatch) => {
  try {
    /* Set loading state */
    dispatch(toggleFetching({key: 'details', toggle: true}));

    /* Fetch study by id */
    const {data} = isAuthenticated
      ? await Api.studies.getVideoById(studyId)
      : await Api.studies.getSharedById(studyId);

    /* Store study Analysis */
    dispatch(setDetails(data));

    return data.data;
  } catch (err) {
    if (err) {
      const error = parseFirstError(err, `Couldn't fetch study #${studyId}.`);

      toast.error(error);

      if (!isAuthenticated) {
        history.push('/auth/signin');
      }
    }
  } finally {
    dispatch(toggleFetching({key: 'details', toggle: false}));
  }
};

export const deleteById = (studyId, refreshFilters) => async (dispatch) => {
  try {
    /* Delete study by id */
    await Api.studies.deleteById(studyId);

    /* Refetch studies */
    dispatch(getAll({filters: refreshFilters}));
  } catch (err) {
    const error = parseFirstError(err, `Couldn't delete study #${studyId}.`);

    toast.error(error);
  }
};

export const createStudy = ({name, type, webPageType}) => async (dispatch, getState) => {
  try {
    const {id: companyId} = getSelectedCompany(getState());

    /* Set loading state */
    dispatch(toggleFetching({key: 'creation', toggle: true}));

    const passedData = {
      studyName: name,
      type,
      webPageType
    };

    /* Create study */
    const {data} = await Api.studies.create({
      companyId,
      data: passedData
    });

    if (data && data.success === false) {
      throw new Error({response: {data}});
    } else {
      dispatch(setCreation({studyId: data.studyId}));

      return data.studyId;
    }
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t create new study.');

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false}));
  }
};

export const createVideoStudy = ({name, type, contentType}) => async (dispatch, getState) => {
  try {
    const {id: companyId} = getSelectedCompany(getState());

    /* Set loading state */
    dispatch(toggleFetching({key: 'creation', toggle: true}));

    const passedData = {
      studyName: name,
      type,
      contentType
    };

    /* Create study */
    const {data} = await Api.studies.createVideo({
      companyId,
      data: passedData
    });

    if (data && data.success === false) {
      throw new Error({response: {data}});
    } else {
      dispatch(setCreation({studyId: data.studyId}));

      return data.studyId;
    }
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t create new study.');

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false}));
  }
};

export const getEditData = ({id, aoi = false}) => async (dispatch) => {
  try {
    /* Set loading state */
    dispatch(toggleFetching({key: 'creation', toggle: true}));

    /* Get edit study data */
    const {data} = await Api.studies.getEditData({id, aoi});

    dispatch(setCreation({id, data}));
  } catch (err) {
    const error = parseFirstError(err, `Couldn't get edit data for study #${id}`);

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false}));
  }
};

export const editStudy = ({
  id, name, type, webPageType
}) => async (dispatch) => {
  try {
    const studyDetails = {
      analysisType: type,
      ...(name && {studyName: name}), // Add studyName only if name is not an empty string
      ...(webPageType && {webPageType}) // Add webPageType only if webPageType is not an empty string
    };

    await Api.studies.edit(id, studyDetails);

    dispatch(updateStudyState({id, name}));
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t edit study Analysis.');

    toast.error(error);
  }
};

export const analyseFold = ({analysisId, start, height}) => async (dispatch) => {
  try {
    const response = await Api.studies.analyseFold(analysisId, {
      fold: {
        start,
        height
      }
    });

    const {data} = response;

    toast.success('Analysing selected image fold.');

    if (data?.success && data?.studyId) {
      history.push(`/single-analysis/${data?.studyId}`);
      dispatch(getFullList('image'));
      dispatch(getAll({filters: initialState.list.filters}));
    }
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t start analyse fold.');

    toast.error(error);
  }
};

export const removeFile = (file) => async (dispatch) => {
  try {
    // See if we're currently in editing mode
    const isEditMode = history.location.pathname.startsWith('/study/edit');

    const id = file.studyResourceId || file.resourceId || window.tmpUploads[file.preview];

    // We wanna remove credits from dropzone component when we add blobs but without submitting,
    // but we dont wanna do that when removing files that already got added
    const applyCreditDiscount = file.preview.startsWith('blob:');

    dispatch(removePreviewFile({id, isEditMode, applyCreditDiscount}));

    dispatch(toggleFetching({key: 'creation', toggle: true, value: 'isRemoving'}));
    await Api.studies.removeImage({id});
  } catch (err) {
    const error = parseFirstError(err, `Couldn't remove file ${file.name}`);

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false, value: 'isRemoving'}));
  }
};

let interval;

const countUp = (start, end, time) => (dispatch) => {
  let i = start;
  interval = setInterval(() => {
    if (++i <= end) {
      dispatch(setProgress(i));
    } else {
      clearInterval(interval);
    }
  }, time / (end - start));
};

export const stopCounting = () => () => {
  clearInterval(interval);
};

export const addFiles = ({id, files, setBadFiles}) => async (dispatch) => {
  try {
    dispatch(toggleFetching({key: 'creation', toggle: true, value: 'isAdding'}));
    dispatch(setProgress(0));
    /* eslint-disable-next-line no-magic-numbers */
    dispatch(countUp(0, 99, 2 * 1000));
    const values = files.filter((f) => !f.resourceId);

    // Upload all new files
    const upload = async (file) => {
      const formData = new FormData();

      formData.append('file', file);

      return Api.studies
        .uploadImage({id, formData})
        .then((data) => {
          window.tmpUploads = {
            ...window.tmpUploads,
            [file.preview]: data.data.id
          };
          dispatch(
            addPreviewFile({
              isEditMode: true,
              isPreview: true,
              resourceId: data.data.id,
              statusKey: 'draft',
              name: data.data.name,
              original: `${process.env.REACT_APP_HOST}/api/files/${data.data.id}`,
              'original-aoi': `${process.env.REACT_APP_HOST}/api/files/${data.data.id}`,
              // eslint-disable-next-line max-len
              thumbnail: `${process.env.REACT_APP_HOST}/api/files/${data.data.id}?additionalFilename=thumbnail`,
              'heatmap-aoi': `${process.env.REACT_APP_HOST}/api/files/${data.data.id}`,
              heatmapUrl: false,
              size: file.size,
              is_long_image: data.data.is_long_image,
              type: 1,
              aesthetics: {},
              annotations: []
            })
          );

          dispatch(stopCounting());
          dispatch(setProgress(MAX_PROGRESS));

          return data;
        })
        .catch((err) => {
          setBadFiles();
          dispatch(stopCounting());
          const error = parseFirstError(err, `Couldn't upload file ${file.name}`);

          toast.error(error);
        });
    };

    const addImageProcess = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => resolve({height: img.height, width: img.width});
        img.onerror = reject;
        img.src = src;
      });
    };

    // eslint-disable-next-line no-inner-declarations
    async function checkImageDimensions (imageUrl) {
      // eslint-disable-next-line no-return-await
      return await addImageProcess(imageUrl);
    }

    const maximumImageDimension = 30000;

    // Upload all new files (the ones that dont have a resourceId)
    await Promise.all(
      // eslint-disable-next-line array-callback-return
      values.map((file, index) => {
        const _URL = window.URL || window.webkitURL;

        const imageUrl = _URL.createObjectURL(file);

        checkImageDimensions(imageUrl)
          .then((data) => {
            if (data.height > maximumImageDimension) {
              throw new Error('The image is to high. Maximum is 15000px.');
            } else {
              upload(file, index);
            }
          })
          .catch((err) => {
            setBadFiles();
            toast.error(err.message || `Couldn't upload file ${file.name}`);
          });
      })
    );
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t upload files');

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false, value: 'isAdding'}));
  }
};

export const addVideoFiles = ({id, files, setBadFiles}) => async (dispatch) => {
  try {
    dispatch(toggleFetching({key: 'creation', toggle: true, value: 'isAdding'}));
    dispatch(setProgress(0));

    const uploadSpeedBps = 524000; // Example: 5 Mbps

    const extraTimePer5MB = 10; // Extra 10 seconds for every 5 MB

    const sizeOfChunkMB = 5; // Size of each chunk in MB

    /* eslint-disable-next-line no-magic-numbers */
    const bytesPerMB = 1024 * 1024; // Number of bytes in 1 MB

    const sizes = files.filter((f) => f.size);

    const totalSize = sizes.reduce((total, file) => total + file.size, 0);

    const numberOfChunks = Math.ceil(totalSize / (sizeOfChunkMB * bytesPerMB));

    const totalExtraTime = numberOfChunks * extraTimePer5MB;

    // Calculate total time in seconds (including extra time)
    const totalTimeSec = totalSize / uploadSpeedBps + totalExtraTime;

    /* eslint-disable-next-line no-magic-numbers */
    dispatch(countUp(0, 99, totalTimeSec * 1000));

    const values = files.filter((f) => !f.resourceId);

    // Upload all new files
    const upload = async (file) => {
      try {
        const formData = new FormData();

        formData.append('file', file);

        const uploadResponse = await Api.studies.uploadVideo({id, formData});

        // Update global uploads (consider revising this approach for better state management)
        window.tmpUploads = {
          ...window.tmpUploads,
          [file.preview]: uploadResponse.data.resourceId
        };

        // Create empty resource structure for future thumbnails
        uploadResponse.data = {...uploadResponse.data, resources: {data: []}};

        dispatch(setCreation({id, data: uploadResponse.data}));

        try {
          const thumbData = await Api.studies.generateThumbnailVideo({id});

          // Dispatch an action to add preview file
          dispatch(
            addPreviewFile({
              isEditMode: true,
              isPreview: false,
              resourceId: uploadResponse.data.resourceId,
              statusKey: 'draft',
              name: uploadResponse.data.name,
              original: `${process.env.REACT_APP_HOST}/api/files/${uploadResponse.data.resourceId}`,
              // eslint-disable-next-line max-len
              thumbnail: `${process.env.REACT_APP_HOST}/api/files/${uploadResponse.data.resourceId}?additionalFilename=thumbnail`,
              heatmapUrl: false,
              videoLength: thumbData.data.meta.videoLength,
              videoLimit: thumbData.data.meta.videoLimit,
              size: file.size,
              type: 1,
              aesthetics: {},
              annotations: []
            })
          );
        } catch (thumbnailError) {
          setBadFiles();
          const errMsg = 'Thumbnail could not be generated, please check video file.';

          console.error(errMsg, ', error: ', thumbnailError);
          toast.error(errMsg);
        }

        dispatch(stopCounting());
        dispatch(setProgress(MAX_PROGRESS));

        return uploadResponse;
      } catch (uploadError) {
        setBadFiles();
        dispatch(stopCounting());
        // Default error message
        let errorMessage = `Couldn't upload file ${file.name}.`;

        // Check if the error response has the expected structure
        if (
          uploadError &&
          uploadError.response &&
          uploadError.response.data &&
          uploadError.response.data.errors
        ) {
          const {errors} = uploadError.response.data;

          // Assuming you want to handle only the first error message for simplicity
          const firstErrorKey = Object.keys(errors)[0];

          const firstErrorMessage = errors[firstErrorKey][0];

          // Append the specific error message to the default one
          errorMessage += ` ${firstErrorMessage}`;
        } else if (uploadError.message) {
          // Append the generic error message if available
          errorMessage += ` ${uploadError.message}`;
        }

        console.error(errorMessage);
        toast.error(errorMessage);
      }
    };

    // eslint-disable-next-line no-inner-declarations
    // async function checkImageDimensions (fileUrl) {
    //   // eslint-disable-next-line no-return-await
    //   return await getVideoDuration(fileUrl);
    //   // return await captureVideoThumbnail(fileUrl);
    // }

    // Upload all new files (the ones that dont have a resourceId)
    await Promise.all(
      // eslint-disable-next-line array-callback-return
      values.map((file, index) => {
        upload(file, index);
      })
    );
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t upload files');

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false, value: 'isAdding'}));
  }
};

export const updateStudy = ({
  id, name, type, webPageType
}) => async (dispatch) => {
  try {
    /* Set loading state */
    dispatch(toggleFetching({key: 'creation', toggle: true, value: 'isUpdating'}));

    await dispatch(
      editStudy({
        id,
        name,
        type,
        webPageType
      })
    );
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t update study name');

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false, value: 'isUpdating'}));
  }
};

export const clearAois = (resourceId) => async (dispatch) => {
  try {
    // delete all aois
    await Api.studies.removeAois({resourceId});
    dispatch(clearAoisFor(resourceId));
  } catch (err) {
    const error = parseFirstError(err, 'There was a problem clearing up AOIs.');

    toast.error(error);
  }
};

export const clearAoi = (aoiId) => async (dispatch) => {
  try {
    // delete aoi one by one
    await Api.studies.removeAoi({aoiId});
    dispatch(deleteAoi(aoiId));
  } catch (err) {
    const error = parseFirstError(err, 'There was a problem deleting AOI.');

    toast.error(error);
  }
};

export const changeAoiName = ({aoiId, name}) => async (dispatch) => {
  try {
    const response = await Api.studies.updateAoi({aoiId, name});

    if (response && response.data && response.data.benchmark) {
      dispatch(
        updateAoi({
          text: name,
          benchmark: response.data.benchmark,
          id: aoiId,
        })
      );
    }
  } catch (err) {
    const error = parseFirstError(err, 'There was a problem updating AOI.');

    toast.error(error);
  }
};

export const copyAoisTo = ({resourceId, studies}) => async () => {
  try {
    const response = await Api.studies.copyAoisTo({resourceId, studies});

    if (response && response.data && response.data.success) {
      toast.success('AOIs copying started. Please wait for processing finish.');
    }
  } catch (err) {
    const error = parseFirstError(err, 'There was a problem copying AOIs.');

    toast.error(error);
  }
};

export const clearAutoAois = (resourceId) => async (dispatch) => {
  try {
    // delete all auto AOIs for one study
    await Api.studies.removeAutoAois({resourceId});

    dispatch(clearAutoAoisFor(resourceId));
  } catch (err) {
    const error = parseFirstError(err, 'There was a problem clearing up auto AOIs.');

    toast.error(error);
  }
};

export const initFocusMapGeneration = ({id}) => async () => {
  try {
    await Api.studies.generateFocusMap(id);
  } catch (err) {
    toast.error('Couldn\'t generate focus map');
  }
};

// eslint-disable-next-line no-shadow
export const startStudy = ({id, crop}) => async (dispatch) => {
  try {
    /* Set loading state for study */
    dispatch(toggleFetching({key: 'creation', toggle: true, value: 'isSubmitting'}));

    // Call api to start the study
    await Api.studies.startStudy({id, crop});

    // Re-fetch user profile since we've used some credits. Update: credits consumed only after processing
    // dispatch(fetchUserProfile());
  } catch (err) {
    const error = parseFirstError(err, 'There was an error while processing your study.');

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false, value: 'isSubmitting'}));
  }
};

export const analyseAllStudies = ({studies, type = 'image'}) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    /* Set loading state for study */
    dispatch(toggleFetching({key: 'creation', toggle: true, value: 'isSubmitting'}));

    // Call api to start the study
    if (type === 'video') {
      await Api.studies.analyseVideoStudies({companyId, studies});
    } else {
      await Api.studies.analyseStudies({companyId, studies});
    }

    // Re-fetch user profile since we've used some credits. Credits consumed after processing
    // dispatch(fetchUserProfile());
  } catch (err) {
    const error = parseFirstError(err, 'There was an error while processing your study.');

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'creation', toggle: false, value: 'isSubmitting'}));
  }
};

export const downloadResource = ({url}) => async () => {
  try {
    // Call api to start generating the zip file for download
    const {
      data: {channel}
    } = await Api.studies.downloadResource(`${url}&deviceId=${getCorrelationId()}`);

    if (channel) {
      subscribeOnce(channel);
      toast.success('Your image is rendering and will soon be downloaded.');
    } else {
      toast.error('Couldn\'t download resource');
    }
  } catch (err) {
    const error = parseFirstError(err, 'There was an error generating your download.');

    toast.error(error);
  }
};

export const downloadImageResource = ({
  url, name = 'image', flag, aoisLayer
}) => async () => {
  try {
    // Call API to start generating the video file for download
    const response = await Api.studies.downloadResourceBlob(
      `${url}&deviceId=${getCorrelationId()}`
    );

    // Assuming the API's response includes the video file as a blob
    if (response.data) {
      const tempUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');

      link.href = tempUrl;

      if (flag & FEATURES_FLAGS.HEATMAP && aoisLayer) {
        link.setAttribute('download', `heatmap_aoi_${name}.jpg`);
      } else if (flag & FEATURES_FLAGS.HEATMAP && !aoisLayer) {
        link.setAttribute('download', `heatmap_${name}.jpg`);
      } else if (flag & FEATURES_FLAGS.FOCUS) {
        link.setAttribute('download', `focus_${name}.jpg`);
      } else if (flag & FEATURES_FLAGS.CONTRAST) {
        link.setAttribute('download', `contrast_${name}.jpg`);
      } else if (flag & FEATURES_FLAGS.NONE && aoisLayer) {
        link.setAttribute('download', `aoi_${name}.jpg`);
      } else {
        link.setAttribute('download', `${name}.jpg`);
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(tempUrl);
    } else {
      toast.error('Couldn\'t download resource');
    }
  } catch (err) {
    const error = parseFirstError(err, 'There was an error generating your download.');

    toast.error(error);
  }
};

export const downloadVideoResource = ({
  url, name = 'video', flag, aoisLayer
}) => async () => {
  try {
    // Call API to start generating the video file for download
    const response = await Api.studies.downloadResourceBlob(
      `${url}&deviceId=${getCorrelationId()}`
    );

    // Assuming the API's response includes the video file as a blob
    if (response.data) {
      const tempUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');

      link.href = tempUrl;

      if (flag & FEATURES_FLAGS.HEATMAP && aoisLayer) {
        link.setAttribute('download', `heatmap_aoi_${name}.mp4`);
      } else if (flag & FEATURES_FLAGS.HEATMAP) {
        link.setAttribute('download', `heatmap_${name}.mp4`);
      } else if (flag & FEATURES_FLAGS.FOCUS) {
        link.setAttribute('download', `focus_${name}.mp4`);
      } else if (flag & FEATURES_FLAGS.NONE && aoisLayer) {
        link.setAttribute('download', `aoi_${name}.mp4`);
      } else {
        link.setAttribute('download', `${name}.mp4`);
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(tempUrl);

      toast.success('Your video will soon be downloaded.');
    } else {
      toast.error('Couldn\'t download resource');
    }
  } catch (err) {
    const error = parseFirstError(err, 'There was an error generating your download.');

    toast.error(error);
  }
};

export const downloadStudy = ({id}) => async () => {
  try {
    // Call api to start generating the zip file for download
    const {
      data: {channel}
    } = await Api.studies.downloadStudy({id, deviceId: getCorrelationId()});

    if (channel) {
      subscribeOnce(channel);
      toast.success('Your analysis will soon be downloaded.');
    } else {
      toast.error('Couldn\'t download analysis');
    }
  } catch (err) {
    const error = parseFirstError(err, 'There was an error generating your download.');

    toast.error(error);
  }
};

export const createAOI = ({
  geometry, fold, resource, ...rest
}) => async (dispatch) => {
  try {
    const data = {
      // Score must be a value between 0-1
      // aoiValue: aoiValue / PERCENTAGE,
      text: rest.data.text || ''
    };

    triggerCustomEvent(CUSTOM_EVENTS.DREW_AOI);
    // Persist newly created AOI with backend api
    const res = await Api.studies.calculateAoi({
      resourceId: resource.resourceId,
      aoi: {
        geometry,
        fold,
        ...data
      }
    });

    // Persist newly created AOI in local state
    dispatch(
      storeAoi({
        resourceId: resource.resourceId,
        geometry,
        data: {
          ...data,
          aoiValue: res.data.data.aoiValue,
          recommendations: res.data.data.recommendations,
          benchmark: res.data.data.benchmark,
          id: res.data.data.id
        }
      })
    );
  } catch (err) {
    dispatch(storeAoi({}));
    toast.error('There was an error trying to calculate  AOI');
    Sentry.captureException(err);
  }
};

export const addUrlToStudy = ({
  id, url, fullPage, failCallback
}) => (dispatch) => {
  /* Set loading state for study */
  dispatch(toggleFetching({key: 'creation', toggle: true, value: 'isScraping'}));
  dispatch(setProgress(0));
  /* eslint-disable-next-line no-magic-numbers */
  dispatch(countUp(0, 99, 120 * 1000));

  // Call api to start the study
  Api.studies
    .addUrlToStudy({id, url, fullPage})
    .then(() => {})
    .catch((err) => {
      dispatch(stopCounting());
      failCallback();
      const error = parseFirstError(err, 'There was an error while saving url.');

      toast.error(error);
      dispatch(toggleFetching({key: 'creation', toggle: false, value: 'isScraping'}));
    });
};

export const setSharedValue = ({id, shared, projectId}) => async (dispatch) => {
  try {
    // Call api to change shared value of the study
    const response = await Api.studies.setSharedValue({id, shared});

    if (response.data && response.data.success) {
      toast.success('Your analysis sharing was changed');

      if (projectId) {
        dispatch(
          updateStudyShareStatusInsideProject({
            studyId: id,
            isShared: response.data.share
          })
        );
      } else {
        dispatch(updateStudyShareStatus({studyId: id, isShared: response.data.share}));
      }
    }
  } catch (err) {
    const error = parseFirstError(err, 'There was an error while setting the study as shared.');

    toast.error(error);
  }
};

export const shareStudyWithUser = (studyId, userId) => async () => {
  try {
    await Api.studies.shareWithUser(studyId, userId);
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t change share to user');

    toast.error(error);
  }
};

export const unShareStudyWithUser = (studyId, userId) => async () => {
  try {
    await Api.studies.unShareWithUser(studyId, userId);
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t change share to user');

    toast.error(error);
  }
};

export const getStudySharedInfo = (id) => async () => {
  try {
    return await Api.studies.sharedInfo(id);
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t get share info');

    toast.error(error);
  }
};

export const generateRecommendations = ({analysisId, category}) => async (dispatch) => {
  try {
    const response = await Api.generative.generateRecommendations({analysisId, category});

    if (response.data && response.data.success) {
      toast.success('We successfully requested recommendations, please wait for response.');
    }
  } catch (err) {
    if (err.response) {
      // HTTP CODES
      if (err.response.status === HTTP_CODE_NOT_PROCESSED &&
        err.response.data.errors?.companyId?.includes('Plan has not enough credits. Please upgrade.')) {
        toast.error('Not enough credits to request recommendations. Please upgrade your plan.');
      } else {
        toast.error('Sorry, something went wrong, couldn\'t request recommendations.');
      }
    } else {
      toast.error('Sorry, something went wrong, couldn\'t request recommendations.');
    }
    dispatch(cancelRecommendationRequest(analysisId));
    Sentry.captureException('Error generating recommendations', err);
    console.error(err);
  }
};

export const generateAoiRecommendations = ({aoiId, analysisId}) => async (dispatch) => {
  try {
    const response = await Api.generative.generateAoiRecommendations({aoiId, analysisId});

    if (response.data && response.data.success) {
      toast.success('We successfully requested recommendations, please wait for response.');
    }
  } catch (err) {
    if (err.response) {
      // HTTP CODES
      if (err.response.status === HTTP_CODE_NOT_PROCESSED &&
        err.response.data.errors?.companyId?.includes('Plan has not enough credits. Please upgrade.')) {
        toast.error('Not enough credits to request recommendations. Please upgrade your plan.');
      } else {
        Sentry.captureException('Error generating AOI recommendations', err);
        toast.error('Sorry, something went wrong, couldn\'t request recommendations.');
      }
    } else {
      toast.error('Sorry, something went wrong, couldn\'t request recommendations.');
    }
    Sentry.captureException('Error generating AOI recommendations', err);
    dispatch(cancelRecommendationRequest(aoiId));
    console.error(err);
  }
};

/* SELECTORS */
const _getStudiesList = (state) => state?.studies?.list;

export const getStudiesSelector = createSelector(_getStudiesList, (list = {}) => ({
  isLoading: list.isLoading,
  didSearch: list.didSearch,
  filters: list.filters,
  meta: {
    totalItems: list.meta.total,
    currentPage: list.meta.current_page,
    totalPages: list.meta.last_page
  },
  data: list.data.map((study) => {
    const img = getUrlWithToken(study.studyThumbnail);

    return {
      id: study.id,
      status: study.statusKey,
      name: study.name,
      images: study.imagesCount,
      hasThumb: !!study.studyThumbnail,
      preview: {
        styles: {
          backgroundImage: img && `url(${img})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }
      },
      createdOn: study.createdAt,
      isShared: study.isShared
    };
  })
}));

export const getStudiesForComparisonSelector = createSelector(_getStudiesList, (list = {}) => {
  return list.data.filter((s) => s.statusKey === 'finished' && s.imagesCount > 0);
});

const _getStudiesDetails = (state) => state?.studies?.details;

export const _getStudyDetails = (details = {}) => ({
  isLoading: details.isLoading,
  didSearch: details.didSearch,
  data: get(details, 'data', []),
  hasDrafts: details.data.some((x) => ['draft', 'scrapped'].includes(x.statusKey)),
  firstResource: {
    status: get(details, 'data[0].statusKey')
  },
  meta: {
    status: get(details, 'meta.study.statusKey'),
    createdOn: get(details, 'meta.study.createdAt'),
    name: get(details, 'meta.study.name'),
    fileType: get(details, 'meta.study.fileType'),
    analysisId: get(details, 'meta.study.id'),
    analysisType: get(details, 'meta.study.analysisType'),
    webPageType: get(details, 'meta.study.webPageType'),
    benchmarkData: get(details, 'meta.study.benchmark'),
    downloadStudyUrl: get(details, 'meta.study.zipUrl'),
    shared: get(details, 'meta.study.share'),
    ownerId: get(details, 'meta.study.owner_id')
  }
});

export const selectStudyGenerativeData = (state) => {
  return state.studies.details?.data[0]?.generative;
};

export const selectStudyAnnotationData = (state) => {
  return state.studies.details?.data[0]?.annotations;
};

export const getShowGenerativeRecommendation = (state) => {
  return state.studies.showGenerativeRecommendation;
};

export const getDetailsSelector = createSelector(_getStudiesDetails, _getStudyDetails);

export const getViewportSelector = (state) => state.studies.viewport;
