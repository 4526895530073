/* eslint-disable no-shadow,no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Api from '../../services/api';
import history from '../router/history';
import {unprocessableEntityStatusCode} from '../../constants/responseCodes';
import {clearLocalStorage} from '../storage';
import {fetchUserProfile} from '../../reducers/settings';
import store from '../../utils/store/configure';

const axiosInstance = axios.create();

function getAccessToken () {
  return localStorage.getItem('token');
}

axiosInstance.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;

  return request;
});

function parseReq (req) {
  // Initialize r with error_description as undefined
  let r = {
    error_description: undefined
  };

  // Check if req and req.response are defined
  if (req && req.response) {
    try {
      // Attempt to parse the response
      r = JSON.parse(req.response);
    } catch (e) {
      // If JSON parsing fails, return the initialized object r
      return r;
    }
  }

  // Return the parsed object or the initialized object r
  return r;
}

// Function that will be called to refresh authorization
// Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest) => {
  const res = parseReq(failedRequest.request);

  // Check if the error description exists and the request is to the /oauth/token endpoint
  if (res.error_description && failedRequest.request.responseURL.includes('/oauth/token')) {
    return Promise.resolve(failedRequest);
  }

  try {
    // Attempt to refresh the token
    const res = await Api.auth.refreshToken();

    const {access_token: token, refresh_token} = res.data;

    // Store the new tokens
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refresh_token);

    // Update the failed request with the new token
    failedRequest.response.config.headers.Authentication = `Bearer ${token}`;

    history.push('/');
    // will force a reload of the page and fetch resources
    window.location.reload();

    // Resolve the promise with the updated request
    return Promise.resolve();
  } catch (e) {
    console.error('Could not refresh previous session token. Redirecting to login page.');
    // Handle the failed token refresh
    history.push('/auth/logout');
    clearLocalStorage();

    // Reject the promise to indicate failure
    return Promise.reject(e);
  }
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {pauseInstanceWhileRefreshing: true});

// If we ever get a 422, redirect to /auth/verify_email flow
axiosInstance.interceptors.response.use(null, (error) => {
  if (error?.response?.status === unprocessableEntityStatusCode) {
    const responseData = error.response.data;

    if (!responseData.success && responseData.errors?.user) {
      if (!window.location.pathname.includes('/auth/verify_email/')) {
        history.push('/auth/verify_email');
      }
    }
  }

  return Promise.reject(error);
});

export default axiosInstance;
